import { gql } from "@apollo/client"

const CREATE_TEAM_INVITES = gql`
  mutation createTeamInvites($input: CreateTeamInput) {
    createTeamInvites(input: $input) {
      message
      error
      status
    }
  }
`

const RESEND_TEAM_INVITES = gql`
  mutation resendTeamInvites($input: CreateTeamInput) {
    resendTeamInvites(input: $input) {
      message
      error
      status
    }
  }
`

const VERIFY_TEAM_INVITES = gql`
  mutation verifyTeamInvites($input: VerifyTeamInviteInput!) {
    verifyTeamInvites(input: $input) {
      message
      error
      status
    }
  }
`

const REMOVE_TEAM_MEMBER = gql`
  mutation removeTeamMember(
    $plan_id: ID!
    $email: EmailAddress!
    $is_preplanning_plan: Boolean!
    $team_access_timing: String!
  ) {
    removeTeamMember(
      plan_id: $plan_id
      email: $email
      is_preplanning_plan: $is_preplanning_plan
      team_access_timing: $team_access_timing
    ) {
      message
      status
      error
    }
  }
`

const UPDATE_TEAM_MEMBER = gql`
  mutation updateTeamMember(
    $plan_id: ID!
    $email: EmailAddress!
    $name: String!
    $is_preplanning_plan: Boolean!
    $team_access_timing: String!
  ) {
    updateTeamMember(
      plan_id: $plan_id
      email: $email
      name: $name
      team_access_timing: $team_access_timing
      is_preplanning_plan: $is_preplanning_plan
    ) {
      message
      status
      error
    }
  }
`

const CHANGE_TEAM_MEMBER_ROLE_TIMING = gql`
  mutation changeMemberRoleAndTiming(
    $plan_id: ID!
    $email: EmailAddress!
    $role: String!
    $timing: String!
  ) {
    changeMemberRoleAndTiming(
      plan_id: $plan_id
      email: $email
      role: $role
      timing: $timing
    ) {
      message
      error
      status
    }
  }
`

export {
  CREATE_TEAM_INVITES,
  RESEND_TEAM_INVITES,
  VERIFY_TEAM_INVITES,
  REMOVE_TEAM_MEMBER,
  CHANGE_TEAM_MEMBER_ROLE_TIMING,
  UPDATE_TEAM_MEMBER,
}
