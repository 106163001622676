import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Modal from "../modals/modal"
import DeleteIcon from "../svg-components/delete-icon"
import { useMutation } from "@apollo/client"
import { useAlert } from "react-alert"
import {
  UPDATE_TEAM_MEMBER,
  RESEND_TEAM_INVITES,
} from "../../graphql/mutations/team"
import Button from "../button"

const EditSharingModal = ({
  closeModal,
  plan,
  collaborator,
  onDelete,
  onCompleted,
  isBeneficiary,
  isPrePlanningPlan,
}) => {
  const alert = useAlert()

  const [member, setMember] = useState({})

  const [updateTeamMember, { loading }] = useMutation(UPDATE_TEAM_MEMBER, {
    onCompleted,
  })
  const [resendTeamInvite, { loading: resending }] =
    useMutation(RESEND_TEAM_INVITES)

  useEffect(() => {
    if (!collaborator) return
    setMember({
      ...collaborator,
      firstName: collaborator.name.split(" ")[0],
      lastName: collaborator.name.split(" ")[1],
    })
  }, [collaborator])

  const handleChange = (e) => {
    const { name, value } = e.target
    setMember((prevState) => ({ ...prevState, [name]: value }))
  }

  const submit = (e) => {
    e.preventDefault()
    const variables = {
      plan_id: plan.id,
      email: member.email,
      name: `${member.firstName} ${member.lastName}`,
      is_preplanning_plan: isPrePlanningPlan,
      team_access_timing: isBeneficiary ? "post-loss" : "now",
    }

    if (!member.firstName || !member.lastName) {
      alert.removeAll()
      alert.error("Please fill in all the fields")
      return
    }
    updateTeamMember({ variables })
      .then(() => {
        alert.success(
          `Updated Invite record for ${variables.email} successfully.`
        )
        closeModal()
      })
      .catch(() => {
        alert.error(`We weren't able to update record for ${member.email}.`)
      })
  }

  const onResend = () => {
    const variables = {
      plan_id: plan.id,
      is_preplanning_plan: isPrePlanningPlan,
      email: member.email,
      first_name: member.firstName,
      last_name: member.lastName,
      role: "Admin",
      team_access_timing: isBeneficiary ? "post-loss" : "now",
    }

    if (
      !variables.email ||
      !variables.first_name ||
      !variables.last_name ||
      !variables.role
    ) {
      alert.removeAll()
      alert.error("Please fill in all the fields")
      return
    }

    resendTeamInvite({ variables: { input: variables } })
      .then(() => {
        alert.success(
          `Success! We've sent an invitation. Check in to make sure they accept.`
        )
        closeModal()
      })
      .catch((err) => {
        alert.error(
          err.message || `We weren't able to resend invite ${member.firstName}.`
        )
      })
  }
  return (
    <Modal closeModal={closeModal} modalClasses="w-full max-w-lg">
      <div className="relative p-6 flex-auto">
        <h2 className="text-3xl md:text-4xl font-extrabold text-center mb-0">
          {`Edit ${!isBeneficiary ? "Collaborator" : "Recipient"}`}
        </h2>
        <div className="flex justify-center flex-wrap my-4">
          {member.status === "pending" && (
            <Button
              handleOnClick={onResend}
              disabled={resending}
              style={{ outline: "none" }}
              buttonType="flex flex-row items-center mx-auto sm:mx-0 sm:mr-3 mb-2 sm:mb-0 text-grey appearance-none leading-none font-semibold block"
              eventName={{
                eventDomain: "App",
                eventLocation: "Edit Sharing Modal",
                eventAction: "Click",
                eventActionName: "Resend Invite",
              }}
            >
              <span className="material-icons">mail_outline</span>
              <span className="ml-2 my-auto block">
                {resending ? "Resending" : "Resend email"}
              </span>
            </Button>
          )}
          <Button
            handleOnClick={onDelete}
            style={{ outline: "none" }}
            buttonType="flex flex-row items-center ml-0 sm:ml-3 mb-2 sm:mb-0 text-grey appearance-none leading-none font-semibold block"
            eventName={{
              eventDomain: "App",
              eventLocation: "Edit Sharing Modal",
              eventAction: "Click",
              eventActionName: "Remove",
            }}
          >
            <DeleteIcon />
            <span className="ml-2 my-auto block">
              Remove{member.status === "pending" && " recipient"}
            </span>
          </Button>
        </div>
        <form onSubmit={submit} className="mt-5">
          <div>
            <label className="text-sm text-grey-dark font-semibold">
              First Name
            </label>
            <input
              name="firstName"
              value={member.firstName || ""}
              onChange={handleChange}
              className="default-form-field w-full"
            />
          </div>
          <div className="mt-1">
            <label className="text-sm text-grey-dark font-semibold">
              Last Name
            </label>
            <input
              name="lastName"
              value={member.lastName || ""}
              onChange={handleChange}
              className="default-form-field w-full"
            />
          </div>

          <div className="mt-1">
            <label className="text-sm text-grey-dark font-semibold">
              Email
            </label>
            <input
              disabled
              name="email"
              defaultValue={member.email}
              className="default-form-field w-full opacity-50"
              type="email"
            />
          </div>
          <Button
            buttonText={loading ? "Saving..." : "Save Changes"}
            buttonType="btn in-app -primary -space-above"
            additionalClasses="block mx-auto"
            disabled={loading}
            eventName={{
              eventDomain: "App",
              eventLocation: "Edit Sharing Modal",
              eventAction: "Click",
              eventActionName: "Save",
            }}
          />
          <Button
            buttonText="Cancel"
            buttonType="btn ghost-button -space-above"
            additionalClasses="block mx-auto"
            handleOnClick={closeModal}
            eventName={{
              eventDomain: "App",
              eventLocation: "Edit Sharing Modal",
              eventAction: "Click",
              eventActionName: "Cancel",
            }}
          />
        </form>
      </div>
    </Modal>
  )
}

EditSharingModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  collaborator: PropTypes.object,
  plan: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  isPrePlanningPlan: PropTypes.bool,
  isBeneficiary: PropTypes.bool,
  onCompleted: PropTypes.func.isRequired,
}

export default EditSharingModal
