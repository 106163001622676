import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const TextLink = ({
  text,
  icon,
  onClick,
  linkPath,
  iconAdditionalClasses,
  textAdditionalClasses,
}) => {
  return (
    <>
      {!onClick ? (
        <Link
          to={linkPath}
          className="flex flex-row cursor-pointer mt-12 md:mt-16 pb-8"
        >
          <i
            className={`material-icons pb-0 body-text text-xl ${iconAdditionalClasses}`}
          >
            {icon}
          </i>
          <p className={`${textAdditionalClasses}`}>{text}</p>
        </Link>
      ) : (
        <div
          onClick={onClick}
          className="sidebar-text flex flex-row items-center text-primary cursor-pointer pb-8 mt-0"
        >
          <i
            className={`material-icons p-0 text-xl -ml-1 ${iconAdditionalClasses}`}
          >
            {icon}
          </i>
          <span className={`ml-1 my-auto ${textAdditionalClasses}`}>
            {text}
          </span>
        </div>
      )}
    </>
  )
}

TextLink.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  linkPath: PropTypes.string,
  onClick: PropTypes.func,
  iconAdditionalClasses: PropTypes.string,
  textAdditionalClasses: PropTypes.string,
}

TextLink.defaultProps = {
  text: "",
  icon: "",
  linkPath: "",
  onClick: undefined,
  iconAdditionalClasses: "",
  textAdditionalClasses: "",
}

export default TextLink
