import React from "react"

const DeleteIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5H4.16667H17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66669 4.99935V3.33268C6.66669 2.89065 6.84228 2.46673 7.15484 2.15417C7.4674 1.84161 7.89133 1.66602 8.33335 1.66602H11.6667C12.1087 1.66602 12.5326 1.84161 12.8452 2.15417C13.1578 2.46673 13.3334 2.89065 13.3334 3.33268V4.99935M15.8334 4.99935V16.666C15.8334 17.108 15.6578 17.532 15.3452 17.8445C15.0326 18.1571 14.6087 18.3327 14.1667 18.3327H5.83335C5.39133 18.3327 4.9674 18.1571 4.65484 17.8445C4.34228 17.532 4.16669 17.108 4.16669 16.666V4.99935H15.8334Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DeleteIcon
